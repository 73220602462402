import React from "react";
import { navigate } from 'gatsby';
import { useForm } from "react-hook-form";
// import Recaptcha from "react-google-recaptcha";

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `)
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = ({name}) => {
  const [state, setState] = React.useState({})
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange"
  });
  const recaptchaRef = React.createRef()

  const handleChange = e => setState({ ...state, [e.target.name]: e.target.value })

  const onSubmit = data => {

    // Needs to be fixed captcha exciting together with form validation
    // recaptchaRef.current.executeAsync()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": name ? name : 'contact',
        ...state,
      })
    })
      // .then(() => alert("Success!"))
      .then(() => navigate('/thanks'))
      .catch(error => alert(error));
  };


  return (
    <form
      name={name ? name : 'contact'}
      method="post"
      action="/thanks"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value={name ? name : 'contact'} />
      <div className="form-group">
        <input
          type="text"
          name={'name'}
          placeholder={'Name*'}
          className={`form-control ${errors.name && 'form-control-error'}`}
          ref={register({ required: "This field is required" })}
          onChange={handleChange}
        />
        {errors.name && <span role="alert">{errors.name.message}</span>}
        <input
          type="email"
          name={'email'}
          placeholder={'Email*'}
          className={`form-control ${errors.email && 'form-control-error'}`}
          ref={register({
            required: "This field is required",
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: "Entered value does not match email format"
            }
          })}
          onChange={handleChange} />
        {errors.email && <span role="alert">{errors.email.message}</span>}
        <input
          type="text"
          name={'country'}
          placeholder={'Country*'}
          className={`form-control ${errors.country && 'form-control-error'}`}
          ref={register({ required: "This field is required" })}
          onChange={handleChange} />
        {errors.country && <span role="alert">{errors.country.message}</span>}
        <input
          type="phone"
          name={'phone'}
          placeholder={'Phone (optional)'}
          className={`form-control ${errors.phone && 'form-control-error'}`}
          // ref={register({
          //   pattern: {
          //     value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
          //     message: "Entered value does not match phone format"
          //   }
          // })}
          onChange={handleChange} />
        {errors.phone && <span role="alert">{errors.phone.message}</span>}
        <input
          type="text"
          name={'companyName'}
          placeholder={'Company name (optional)'}
          className={'form-control'}
          onChange={handleChange}
        />
        <textarea
          name="message"
          placeholder={'Message*'}
          rows="5"
          className={`form-control ${errors.message && 'form-control-error'}`}
          ref={register({ required: "This field is required" })}
          onChange={handleChange}
        />
        {errors.message && <span role="alert">{errors.message.message}</span>}
        {/*<Recaptcha*/}
        {/*  name="g-recaptcha-response"*/}
        {/*  size="invisible"*/}
        {/*  ref={recaptchaRef}*/}
        {/*  sitekey={RECAPTCHA_KEY}*/}
        {/*/>*/}
        <button type='submit' className="btn submit-btn">Submit</button>
      </div>
    </form>
  )
}

export default Form